import React, { FC, memo, useState, useEffect, useMemo } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import { Toggle } from '@/shared/ui/Toggle'
import { Switch } from '@/shared/ui/Switch'
import { Label } from '@/shared/ui/Label'
import { useFormik } from 'formik'
import { useUserState } from '@/store/user'
import { UserRole } from '@/store/user/user'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'
import CancelDeleteButtons from '@/components/CancelDeleteButtons'

import './NotificationSettingsModal.scss'

const NotificationSettingsModalId = 50

interface ChangeNotificationSettingsForm {
    isMessages: boolean
    isOrders: boolean
    isLoadBoard: boolean
    channel: string
}

interface ChangeNotificationSettingsModalProps {}

const NotificationSettingsModal: FC<ChangeNotificationSettingsModalProps> = () => {
    const { state: userState } = useUserState()
    const isSupplyAccount = userState.userInfo?.role === UserRole.WAREHOUSE_MANAGER
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const formik = useFormik<ChangeNotificationSettingsForm>({
        validateOnBlur: true,
        validateOnChange: false,
        enableReinitialize: true,
        initialValues: {
            isMessages: false,
            isOrders: false,
            isLoadBoard: false,
            channel: 'email',
        },
        onSubmit: async (values) => {
            const {
                isMessages,
                isOrders,
                isLoadBoard,
                channel
            } = values
            try {
                formik.setSubmitting(true)
                setErrorMessage('')
                // PUT request to save notification preferences
                await HttpV2.put('/notifications/preferences', {
                    preferences: [
                        { typeKey: 'chat', channelKey: channel, enabled: isMessages },
                        { typeKey: 'order', channelKey: channel, enabled: isOrders },
                        { typeKey: 'load-board', channelKey: channel, enabled: isLoadBoard },
                        { typeKey: 'system', channelKey: channel, enabled: true },
                    ]
                })

                ModalRegistry.get().close(NotificationSettingsModalId)
            } catch (error: any) {
                const message = error.response?.data?.message
                setErrorMessage(message)
            } finally {
                formik.setSubmitting(false)
            }
        },
    })

    // Fetch notification settings when the component is mounted
    useEffect(() => {
        const fetchNotificationSettings = async () => {
            try {
                setIsLoading(true)
                // Fetch notification settings from the server for the user
                const response = await HttpV2.get('/notifications/preferences')
                const data = response.data

                // Update form values with fetched data
                if (data.length > 0) {
                    console.log(data[0].key.split(':')[0])
                    const values = {
                        isMessages: data.find((p: any) => p.key.includes('chat'))?.enabled,
                        isOrders: data.find((p: any) => p.key.includes('order'))?.enabled,
                        isLoadBoard: data.find((p: any) => p.key.includes('load-board'))?.enabled,
                        channel: data[0] ? data[0].key.split(':')[0] : 'email'
                    }
                    formik.setValues(values)
                }

            } catch (error: any) {
                const message = error.response?.data?.message
                setErrorMessage(message)
            } finally {
                setIsLoading(false)
            }
        }

        fetchNotificationSettings()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="modal-notification-settings">
            {isLoading ? (
                <div>Loading...</div>
            ) : (
            <form onSubmit={formik.handleSubmit} className="notification-settings-form">
                <div className="">
                    <div className="text-sm">Select applicable:</div>

                    <div className="flex items-center gap-2 mt-1">
                        <Toggle
                            variant="outlined"
                            className="w-[140px] bg-background-tertiary"
                            name="isMessages"
                            defaultPressed={formik.values.isMessages}
                            onPressedChange={(pressed) => formik.setFieldValue('isMessages', pressed)}
                        >
                            Messages
                        </Toggle>
                        <Toggle
                            variant="outlined"
                            className="w-[140px] bg-background-tertiary"
                            name="IsOrders"
                            defaultPressed={formik.values.isOrders}
                            onPressedChange={(pressed) => formik.setFieldValue('isOrders', pressed)}
                        >
                            Orders
                        </Toggle>
                        {!isSupplyAccount ? <Toggle
                            variant="outlined"
                            className="w-[140px] bg-background-tertiary"
                            name="IsLoadBoard"
                            defaultPressed={formik.values.isLoadBoard}
                            onPressedChange={(pressed) => formik.setFieldValue('isLoadBoard', pressed)}
                        >
                            Load Board
                        </Toggle> : null}
                    </div>
                </div>

                <div className="flex items-center space-x-2">
                    <Switch
                        id="email-notifications"
                        name="chanel"
                        defaultChecked={formik.values.channel === 'email'}
                        checked={formik.values.channel === 'email'}
                        onCheckedChange={() => formik.setFieldValue('channel', 'email')}
                    />
                    <Label htmlFor="email-notifications" className="font-normal text-sm">Email Notifications</Label>
                </div>
                <div className="flex items-center space-x-2">
                    <Switch
                        id="sms-notifications"
                        name="chanel"
                        defaultChecked={formik.values.channel === 'sms'}
                        checked={formik.values.channel === 'sms'}
                        onCheckedChange={() => formik.setFieldValue('channel', 'sms')}
                    />
                    <Label htmlFor="sms-notifications" className="font-normal text-sm">SMS Notifications</Label>
                </div>


                <CancelDeleteButtons
                    handleCancel={() => ModalRegistry.get().close(NotificationSettingsModalId)}
                    isSubmitting={formik.isSubmitting}
                    disableSubmit={!formik.isValid}
                    textSubmit="Save"
                    stylesSubmit={{ width: '80px' }}
                    buttonsPosition="right"
                    errorMessage={errorMessage}
                />
            </form>
            )}
        </div>
    )
}

ModalRegistry.get().register<ChangeNotificationSettingsModalProps>(NotificationSettingsModalId, {
    id: 'ChangeNotificationSettingsModalProps',
    className: 'modal-notification-settings',
    size: 'medium',
    Component: memo(NotificationSettingsModal),
    title: 'Notification Settings',
})

export const showNotificationSettingsModal = (props: ModalPartialProps<ChangeNotificationSettingsModalProps>): void =>
    ModalRegistry.get().show<ChangeNotificationSettingsModalProps>(NotificationSettingsModalId, props)

export default memo(NotificationSettingsModal)
