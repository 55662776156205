import * as React from 'react'

import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@/shared/utils/cn'

const badgeVariants = cva(
    'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
    {
        variants: {
            variant: {
                default: 'bg-primary text-white hover:bg-primary/90',
                secondary: 'border border-border bg-background-tertiary hover:bg-accent-light',
                outlined: 'border border-primary text-foreground hover:bg-primary/10',
                info: 'bg-primary-light text-primary-dark hover:bg-primary/50',
                success: 'bg-success-light text-success-dark hover:bg-success/50',
                warning: 'bg-warning-light text-warning-dark hover:bg-warning/50',
                danger: 'bg-danger text-white hover:bg-danger/90',
                plain: 'text-foreground-primary',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
)

export type BadgeProps = React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof badgeVariants>

function Badge({ className, variant, ...props }: BadgeProps) {
    return <div className={cn(badgeVariants({ variant }), className)} {...props} />
}

export { Badge, badgeVariants }
