import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { PopoverContent, PopoverRoot, PopoverTrigger, PopoverTriggerWrapper } from '@/shared/ui/Popover'
import { TabsRoot, TabsList, TabsTrigger } from '@/shared/ui/Tabs'
import { InBell } from '@/shared/ui/Icons/InBell'
import { Button } from '@/shared/ui/Button'
import { Badge } from '@/shared/ui/Badge'
import { Avatar, AvatarFallback, AvatarImage } from '@/shared/ui/Avatar'
import dayjs from 'dayjs'
import cn from 'classnames'
import { useNotifications } from '@/entities/notifications/api/useNotifications'
import { ScrollArea } from '@/shared/ui/ScrollArea'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { notificationsActions } from '@/entities/notifications/model/slice'
import { NotificationDto } from '@/entities/notifications/model/dto'
import { useNavigate } from 'react-router-dom'
import logo from 'images/logocircle.png'
import pdf from 'images/pdf.svg'
import temp_notification from 'images/temp_notification.png'
import './index.scss'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'
import { FaXmark, FaCircle } from 'react-icons/fa6'
import { showNotificationSettingsModal } from '@/parts/Modals/NotificationSettingsModal'

export const NotificationRow: React.FC<
    PropsWithChildren<NotificationDto> & {
        onRead: (id: number) => void
        close: () => void
    }
> = ({ title, message, createdAt, read, id, type, close, sender, entityId, onRead, file = false }) => {
    const clickable = !read || (entityId && type)

    const navigate = useNavigate()
    const navigateTo = useCallback(() => {
        if (type === 'chat') {
            navigate(`/order/${entityId}`)
            close()
        }
    }, [entityId, navigate, type])
    const handleClick = useCallback(
        (event) => {
            if (!read && onRead) {
                onRead(id)
            }
            if (entityId && type) {
                navigateTo()
            }
        },
        [read, onRead, entityId, type, id, navigateTo],
    )

    return (
        <div
            className={cn('m-3.5 gap-3.5 rounded-xl', {
                'bg-white': !read,
                '': read,
                'cursor-pointer': clickable,
            })}
            onClick={handleClick}
            key={id + '_notification'}
        >
            <div className="notification-row flex flex-col mx-4 py-3">
                {/* Avatar and Name Section */}
                <div className="flex items-center mb-2">
                    <div className="notification-row__icon flex items-center justify-center mr-2">
                        <Avatar className="h-8 w-8">
                            {sender?.olimp && <AvatarImage src={logo + 33} alt="OLIMP" />}
                            <AvatarFallback className={
                                cn('text-[12px] text-white',
                                {
                                    'bg-primary': !read,
                                    'bg-slate-500': read,
                                })
                            }>
                                {sender?.shortName ?? 'OLIMP'}
                            </AvatarFallback>
                        </Avatar>
                    </div>
                    {sender && (
                        <span className="text-foreground-primary font-medium" title={sender.name}>
                            {sender.name}
                        </span>
                    )}
                    {!read && (<div className="ml-auto">
                        <FaCircle className="mx-2 text-[12px] text-primary" />
                    </div>)}
                </div>

                {/* Notification Content Section */}
                <div className="notification-row__content flex-1">
                    <div className="notification-row__description text-sm text-foreground-primary max-w-full pr-2 mb-2 flex">
                        <div className="line-clamp-3 flex-1 pr-2">{message}</div>
                        {file ? (
                            <div className="flex-none w-45">
                                <img className="h-[54px] w-[45px]" src={pdf} alt="Document" />
                            </div>
                        ) : null}
                    </div>
                    <div className="notification-row__time text-foreground-secondary/70 text-xs flex items-center">
                        {dayjs(createdAt).fromNow()} <FaCircle className="mx-2 text-[8px]" /> {entityId}
                    </div>
                </div>
            </div>
        </div>
    )
}
const InfiniteScroller: React.FC<{ onReachEnd: (e: any) => void; maxHeight: string | number }> = ({
    onReachEnd,
    children,
    maxHeight,
}) => {
    const scrollViewportRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const current = scrollViewportRef.current
        if (current) {
            current.addEventListener('scroll', onReachEnd)
            return () => {
                current.removeEventListener('scroll', onReachEnd)
            }
        }
    }, [onReachEnd])

    return (
        <ScrollArea
            className="user-notifications__list"
            viewportProps={{ ref: scrollViewportRef, style: { maxHeight } }}
        >
            <div className="">{children}</div>
        </ScrollArea>
    )
}

export const UserNotificationsPopover: React.FC<{
    loadMore: any
    markRead: (id: number) => void
    markAllRead: () => void
    mobile?: boolean
}> = ({ loadMore, markRead, markAllRead, mobile }) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const [open, setOpen] = useState(false)
    const dispatch = useAppDispatch()
    const totalUnread = useAppSelector((state) => state.notifications.unreadNotifications)
    const notifications = useAppSelector((state) => state.notifications.notifications)
    const loading = useAppSelector((state) => state.notifications.notificationsLoading)
    const hasMore = useAppSelector((state) => state.notifications.hasMoreNotifications)

    const hasMoreRef = useRef(hasMore)
    const handleLoadMore = useCallback(() => {
        if (!hasMoreRef.current || loading) return
        loadMore()
    }, [loading, loadMore])

    const handleScroll = useCallback(
        (e: any) => {
            const current = e.target
            if (current) {
                const { scrollTop, scrollHeight, clientHeight } = current
                if (Math.round(scrollTop) + clientHeight + 5 >= scrollHeight) {
                    handleLoadMore()
                }
            }
        },
        [handleLoadMore],
    )

    useEffect(() => {
        hasMoreRef.current = hasMore
    }, [hasMore])

    const handleOpenChange = (isOpen: boolean) => {
        setOpen(isOpen)
        sendCustomEvent('notifications_opened', {
            totalUnread,
        })
    }

    return (
        <PopoverRoot open={open} onOpenChange={(isOpen) => handleOpenChange(isOpen)}>
            <PopoverTriggerWrapper>
                <PopoverTrigger asChild>
                    <Button className="user-notifications__button relative mr-1" variant="ghost">
                        <InBell className={mobile ? 'w-5' : undefined} />

                        {totalUnread > 0 && (
                            <Badge className="circle px-1.5 absolute top-0 right-0.5" variant="danger" color="red">
                                {totalUnread}
                            </Badge>
                        )}
                    </Button>
                </PopoverTrigger>
            </PopoverTriggerWrapper>
            <PopoverContent
                className={cn('p-0 border-gray-2', {
                    'w-[100vw]': mobile,
                    'max-w-[30vw] min-w-[20vw]': !mobile,
                })}
            >
                <div className={'user-notifications__header pl-5 pr-5 pt-4 border-b justify-between'}>
                    <span className="text-foreground-primary font-medium text-xl">Notifications</span>
                    {totalUnread > 0 && (
                        <Button
                            variant="ghost"
                            className="text-primary h-6 !text-sm hover:bg-gray-1"
                            onClick={markAllRead}
                        >
                            Mark all as read
                        </Button>
                    )}
                    <FaXmark
                        onClick={() => setOpen(false)}
                        className="cursor-pointer text-foreground-primary hover:text-gray-800 text-2xl bold float-right"
                    />
                    <div className="leading-10">
                        <TabsRoot defaultValue="all">
                            <TabsList className="space-x-4 h-11 leading-10">
                                <TabsTrigger value="all" className="text-sm">
                                    All
                                    {totalUnread > 0 && (
                                        <span className="px-2 ml-1 text-white text-xs w-[18px] h-[18px] rounded-[4px] bg-danger">
                                            {totalUnread}
                                        </span>
                                    )}
                                </TabsTrigger>
                                <TabsTrigger value="messages" className="text-sm">
                                    Messages
                                    <span className="px-2 ml-1 text-white text-xs w-[18px] h-[18px] rounded-[4px] bg-danger">3</span>
                                </TabsTrigger>
                                <TabsTrigger value="orders" className="text-sm">
                                    Orders
                                    <span className="px-2 ml-1 text-white text-xs w-[18px] h-[18px] rounded-[4px] bg-danger">1</span>
                                </TabsTrigger>
                            </TabsList>
                        </TabsRoot>
                    </div>
                </div>

                <InfiniteScroller onReachEnd={handleScroll} maxHeight={mobile ? '50vh' : 220}>
                    {notifications.map((notification) => (
                        <NotificationRow
                            key={notification.id + '_notification-row'}
                            {...notification}
                            onRead={() => false}
                            close={() => setOpen(false)}
                        />
                    ))}

                    {!loading && notifications.length === 0 && (
                        <div className="flex flex-grow h-[200px] justify-center items-center text-gray-500 text-sm py-2">
                            No notifications
                        </div>
                    )}

                    <div className="flex justify-center items-center text-gray-500 text-sm py-2">
                        {loading ? 'Loading...' : hasMore ? 'Load more' : ''}
                    </div>
                </InfiniteScroller>
                {/* Footer Actions */}
                <div className="flex justify-between items-center px-5 py-3">
                    <a className="text-sm text-primary">Mark all as read</a>
                    <a
                        className="text-sm text-primary"
                        onClick={(e) => {
                            e.preventDefault()
                            showNotificationSettingsModal({
                                props: {},
                            })
                        }}
                    >
                        Notification Settings
                    </a>
                </div>
            </PopoverContent>
        </PopoverRoot>
    )
}

export const UserNotifications: React.FC<{ mobile?: boolean }> = ({ mobile }) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const dispatch = useAppDispatch()

    const notifications = useAppSelector((state) => state.notifications.notifications)
    const hasMore = useAppSelector((state) => state.notifications.hasMoreNotifications)

    const { connected, getNotifications, markSeen, markAllSeen } = useNotifications({
        callbacks: {
            onNotificationReceived: (notification) => {
                dispatch(notificationsActions.addNotification(notification))
            },
            onNotificationsLoaded: (response) => {
                if (response.unread) {
                    dispatch(notificationsActions.setUnreadNotifications(response.unread))
                }
                dispatch(notificationsActions.setNotifications(response.notifications))
                dispatch(notificationsActions.setNotificationsLoading(false))
                dispatch(notificationsActions.setHasMoreNotifications(response.hasMore))
            },
        },
    })

    const loadMore = () => {
        if (hasMore) {
            dispatch(notificationsActions.setNotificationsLoading(true))
            getNotifications(notifications.length)
            sendCustomEvent('notifications_load-more_clicked', {
                notifications_count: notifications.length,
            })
        }
    }

    const markRead = (id: number) => {
        dispatch(notificationsActions.markAsRead(id))

        markSeen(id)
        sendCustomEvent('notifications_mark_read', {
            id,
        })
    }

    const markAllRead = () => {
        dispatch(notificationsActions.markAllAsRead())

        markAllSeen()
        sendCustomEvent('notifications_mark_all_read')
    }

    useEffect(() => {
        if (connected) {
            getNotifications()
        }
    }, [connected])

    return (
        <div className="user-notifications flex justify-center items-center">
            <UserNotificationsPopover
                loadMore={loadMore}
                markRead={markRead}
                markAllRead={markAllRead}
                mobile={mobile}
            />
        </div>
    )
}
